import copy from 'copy-to-clipboard';
import { FC, useEffect, useState } from 'react';
import Modal from '../UI/Modal.tsx';

import copyImg from '../../assets/icons/copy.png';
import facebookImg from '../../assets/icons/facebook.png';
import instagramImg from '../../assets/icons/instagram.webp';
import twitterImg from '../../assets/icons/x.png';
import telegramImg from '../../assets/icons/telegram.png';
import viberImg from '../../assets/icons/viber.png';
import whatsappImg from '../../assets/icons/whatsapp.png';
import { languages } from '../../constants.ts';
import { useAppSelector } from '../../hooks/redux.ts';
import { Elanguages } from '../../store/reducers/TranslateSlice.ts';
import { isDeviceMobile } from '../../utils/getDeviceType.ts';
import { ITranslateItemArray, ITranslateItemString, translations } from '../../utils/translations.tsx';

interface HomeRecomendModalProps {
  active: boolean;
  onClose: (val: boolean) => void;
}

const classNames = {
  title:
    'text-[#fdc800] font-normal text-center text-[30px] mb-[15px] px-[10px]',
  container: 'max-w-[700px] mx-auto px-[15px]',
  contentTxt: 'text-[16px] block text-white whitespace-pre-wrap leading-[1.6]',
};

const HomeRecomendModal: FC<HomeRecomendModalProps> = ({ active, onClose }) => {
  const { user } = useAppSelector((state) => state.UserSlice);

  const [isshareBtnsShowing, setIsshareBtnsShowing] = useState(false);
  const language = useAppSelector((state) => state.TranslateSlice.language);

  const [popupLanguage, setPopupLanguage] = useState<Elanguages>(language);
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    setPopupLanguage(language)
  }, [language])

  const {
    recommendationTitleText,
    shareText,
    recommendationText,
    advantagesText,
    advantage1Text,
    advantage4Text,
    priceSiteText,
    registerForFreeLessonText,
    officialSiteText,
    instagramText,
    advantageLinkText,
    advantage4LinkText,
    copiedText,
  }: {
    recommendationTitleText: ITranslateItemString;
    shareText: ITranslateItemString;
    recommendationText: ITranslateItemString;
    advantagesText: ITranslateItemString;
    advantageLinkText: ITranslateItemString;
    advantage1Text: ITranslateItemArray;
    advantage4Text: ITranslateItemString;
    advantage4LinkText: ITranslateItemString;
    registerForFreeLessonText: ITranslateItemString;
    officialSiteText: ITranslateItemString;
    instagramText: ITranslateItemString;
    priceSiteText: ITranslateItemString;
    copiedText: ITranslateItemString;
  } = translations.recommendModal;

  const referrerData = `id:${user._id}, name:${user.name}, surname:${user.sname}, email:${user.email}`;
  const referrerUrl = `https://araratchess.ru/online?referrer=${btoa(
    escape(referrerData),
  )}&popup=free-lesson`;
  const safelyReferrerUrl = `https://araratchess.ru/online?referrer=${btoa(
    escape(referrerData),
  )}%26popup%3Dfree-lesson`;

  const shareFullTextRu = `*Рекомендую отличную онлайн школу ШАХМАТ!*

🌟 *Преимущества международной школы ""Арарат""
https://drive.google.com/file/d/1fGiYD-2Olxga0ea2wZ8dShLEXKwJT2u2/view?usp=drive_link

- *Запишитесь на бесплатный пробный урок*
- *Узнайте цены*
- *Посмотрите наш Instagram*
👉 Все подробности на официальной странице: ${referrerUrl}

---

*Хотите заработать, помогая школе?*
Да, это возможно, даже если вы ещё не учитесь в нашей школе!

📌 *Что нужно сделать:*
1️⃣ 📩 Отправьте это сообщение: (Facebook, Instagramm, WhatsApp, Telegram, Viber).
- В родительских группах школ и детсадов,
- Близким и друзьям, у которых есть дети от 4 лет.
2️⃣ Разместите пост или сторис в своих соцсетях (Facebook, Instagram, VK).

📞 *Напишите ( WhatsApp - https://wa.me/+37443449221 ) администратору школы, чтобы получить:*
- Готовый текст рекомендации,
- Красивые макеты для публикаций,
- Уникальную ссылку для отслеживания результата и получения бонусов.

Спасибо за поддержку нашей школы и за помощь в развитии шахмат! 🙌`;

  const shareFullTextAm = `*Խորհուրդ եմ տալիս շախմատի և հայոց լեզվի հիանալի օնլայն դպրոց!*

🌟 *""Արարատ"" միջազգային դպրոցի առավելությունները:*
https://drive.google.com/file/d/1UmSGgRU-IITvyrp-1oPUJsK2Ls5sSpxj/view

- *Գրանցվեք անվճար փորձնական դասի *
- *Ծանոթացեք գներին*
- *Տեսեք մեր Instagram էջը*
👉 Բոլոր մանրամասները պաշտոնական էջում: ${referrerUrl}

---

*Ուզո՞ւմ եք գումար վաստակել՝ աջակցելով դպրոցին:*
Այո, դա հնարավոր է, նույնիսկ եթե դուք դեռ չեք սովորում մեր դպրոցում!

📌 *Ինչ պետք է անել:*
1️⃣ 📩 Ուղարկեք այս հաղորդագրությունը: (Facebook, Instagram, WhatsApp, Telegram, Viber):
- Դպրոցների և մանկապարտեզների ծնողական խմբերում,
- Ձեր ընկերներին և ծանոթներին, ովքեր ունեն 4 տարեկանից մեծ երեխաներ։

2️⃣ Տեղադրեք պոստ կամ սթորի ձեր սոցիալական ցանցերում (Facebook, Instagram, VK):

📞 *Կապ հաստատեք ( WhatsApp - https://wa.me/+37443449221 ) դպրոցի ադմինիստրատորի հետ՝ ստանալու համար:*
- Րեկոմենդացիայի պատրաստի տեքստը,
- Գեղեցիկ ձևավորումներ պոստերի համար,
- Ռեֆերալ հղում՝ արդյունքների հետևման և բոնուսների հաշվարկի համար։

🎉 Շնորհակալություն մեր դպրոցին աջակցելու և շախմատը զարգացնելու համար։ 🙌`;

  const shareFullTextEn = `**I highly recommend the excellent online chess school ""Ararat""!*

🌟 **Advantages of the international school ""Ararat"":**
https://drive.google.com/file/d/1sq2Fyi-wWbpamea-O2gizcVsbkbiMGG-/view

- *Sign up for a free trial lesson*
- *Check the prices*
- *Visit our Instagram page*
👉 All details are available on the official page: ${referrerUrl}

---

*Want to earn money while supporting the school?*
Yes, it is possible, even if you are not yet studying at our school!

📌 *What to do:*
1️⃣ 📩 Send this message: (Facebook, Instagram, WhatsApp, Telegram, Viber):
- To parent groups in schools and kindergartens,
- To your friends and acquaintances who have children aged 4 and above.

2️⃣ Post or share a story in your social media (Facebook, Instagram, VK).

📞 *Contact  (WhatsApp - https://wa.me/+37443449221 ) the school administrator to receive:*
- A ready-to-send recommendation text,
- Beautiful templates for your posts,
- A unique link to track results and calculate bonuses.

🎉 Thank you for supporting our school and helping develop chess! 🙌`;

const tgRuTest = `**Рекомендую отличную онлайн школу ШАХМАТ!**

🌟 **Преимущества международной школы "Арарат"**
https://drive.google.com/file/d/1fGiYD-2Olxga0ea2wZ8dShLEXKwJT2u2/view?usp=drive_link  

- **Запишитесь на бесплатный пробный урок**
- **Узнайте цены**
- **Посмотрите наш Instagram**
👉 Все подробности на официальной странице: ${referrerUrl}`;

const tgAmTest = `*Խորհուրդ եմ տալիս շախմատի և հայոց լեզվի հիանալի օնլայն դպրոց!*

🌟 **"Արարատ" միջազգային դպրոցի առավելությունները:** 
https://drive.google.com/file/d/1UmSGgRU-IITvyrp-1oPUJsK2Ls5sSpxj/view 

- **Գրանցվեք անվճար փորձնական դասի **
- **Ծանոթացեք գներին**
- **Տեսեք մեր Instagram էջը**
👉 Բոլոր մանրամասները պաշտոնական էջում: ${referrerUrl}`;
const tgEnTest = `**I highly recommend the excellent online chess school "Ararat"!**

🌟 **Advantages of the international school "Ararat":**
https://drive.google.com/file/d/1sq2Fyi-wWbpamea-O2gizcVsbkbiMGG-/view   

- **Sign up for a free trial lesson** 
- **Check the prices**
- **Visit our Instagram page**
👉 All details are available on the official page: ${referrerUrl}`;

  const shareFullText = popupLanguage === Elanguages.RU ? shareFullTextRu : popupLanguage === Elanguages.AM ? shareFullTextAm : shareFullTextEn
  const telegramShareText = popupLanguage === Elanguages.RU ? tgRuTest : popupLanguage === Elanguages.AM ? tgAmTest : tgEnTest;
  const viberText = telegramShareText.replace(/\*\*/g, '*')
  const toggleShareBtnsShowing = () =>
    setIsshareBtnsShowing((prevState) => !prevState);

  const copyText = () => {
    copy(shareFullText);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 1000);
  };

  const onLangChange = (lang: Elanguages) => {
    setPopupLanguage(lang);
  };

  const telegramLinkRu = `https://t.me/share?url=**%D0%A0%D0%B5%D0%BA%D0%BE%D0%BC%D0%B5%D0%BD%D0%B4%D1%83%D1%8E%20%D0%BE%D1%82%D0%BB%D0%B8%D1%87%D0%BD%D1%83%D1%8E%20%D0%BE%D0%BD%D0%BB%D0%B0%D0%B9%D0%BD%20%D1%88%D0%BA%D0%BE%D0%BB%D1%83%20%D0%A8%D0%90%D0%A5%D0%9C%D0%90%D0%A2!**&text=%0A%0A%F0%9F%8C%9F%20%2A%2A%D0%9F%D1%80%D0%B5%D0%B8%D0%BC%D1%83%D1%89%D0%B5%D1%81%D1%82%D0%B2%D0%B0%20%D0%BC%D0%B5%D0%B6%D0%B4%D1%83%D0%BD%D0%B0%D1%80%D0%BE%D0%B4%D0%BD%D0%BE%D0%B9%20%D1%88%D0%BA%D0%BE%D0%BB%D1%8B%20%22%D0%90%D1%80%D0%B0%D1%80%D0%B0%D1%82%22%2A%2A%0Ahttps%3A%2F%2Fdrive.google.com%2Ffile%2Fd%2F1fGiYD-2Olxga0ea2wZ8dShLEXKwJT2u2%2Fview%3Fusp%3Ddrive_link%20%20%0A%0A-%20%2A%2A%D0%97%D0%B0%D0%BF%D0%B8%D1%88%D0%B8%D1%82%D0%B5%D1%81%D1%8C%20%D0%BD%D0%B0%20%D0%B1%D0%B5%D1%81%D0%BF%D0%BB%D0%B0%D1%82%D0%BD%D1%8B%D0%B9%20%D0%BF%D1%80%D0%BE%D0%B1%D0%BD%D1%8B%D0%B9%20%D1%83%D1%80%D0%BE%D0%BA%2A%2A%0A-%20%2A%2A%D0%A3%D0%B7%D0%BD%D0%B0%D0%B9%D1%82%D0%B5%20%D1%86%D0%B5%D0%BD%D1%8B%2A%2A%0A-%20%2A%2A%D0%9F%D0%BE%D1%81%D0%BC%D0%BE%D1%82%D1%80%D0%B8%D1%82%D0%B5%20%D0%BD%D0%B0%D1%88%20Instagram%2A%2A%0A%F0%9F%91%89%20%D0%92%D1%81%D0%B5%20%D0%BF%D0%BE%D0%B4%D1%80%D0%BE%D0%B1%D0%BD%D0%BE%D1%81%D1%82%D0%B8%20%D0%BD%D0%B0%20%D0%BE%D1%84%D0%B8%D1%86%D0%B8%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE%D0%B9%20%D1%81%D1%82%D1%80%D0%B0%D0%BD%D0%B8%D1%86%D0%B5%3A%20${safelyReferrerUrl}`;
  const telegramLinkAm = `https://t.me/share?url=**%D4%BD%D5%B8%D6%80%D5%B0%D5%B8%D6%82%D6%80%D5%A4%20%D5%A5%D5%B4%20%D5%BF%D5%A1%D5%AC%D5%AB%D5%BD%20%D5%B7%D5%A1%D5%AD%D5%B4%D5%A1%D5%BF%D5%AB%20%D6%87%20%D5%B0%D5%A1%D5%B5%D5%B8%D6%81%20%D5%AC%D5%A5%D5%A6%D5%BE%D5%AB%20%D5%B0%D5%AB%D5%A1%D5%B6%D5%A1%D5%AC%D5%AB%20%D6%85%D5%B6%D5%AC%D5%A1%D5%B5%D5%B6%20%D5%A4%D5%BA%D6%80%D5%B8%D6%81!**&text=%0A%F0%9F%8C%9F%20%2A%2A%22%D4%B1%D6%80%D5%A1%D6%80%D5%A1%D5%BF%22%20%D5%B4%D5%AB%D5%BB%D5%A1%D5%A6%D5%A3%D5%A1%D5%B5%D5%AB%D5%B6%20%D5%A4%D5%BA%D6%80%D5%B8%D6%81%D5%AB%20%D5%A1%D5%BC%D5%A1%D5%BE%D5%A5%D5%AC%D5%B8%D6%82%D5%A9%D5%B5%D5%B8%D6%82%D5%B6%D5%B6%D5%A5%D6%80%D5%A8%3A%2A%2A%20%0Ahttps%3A%2F%2Fdrive.google.com%2Ffile%2Fd%2F1UmSGgRU-IITvyrp-1oPUJsK2Ls5sSpxj%2Fview%20%0A%0A-%20%2A%2A%D4%B3%D6%80%D5%A1%D5%B6%D6%81%D5%BE%D5%A5%D6%84%20%D5%A1%D5%B6%D5%BE%D5%B3%D5%A1%D6%80%20%D6%83%D5%B8%D6%80%D5%B1%D5%B6%D5%A1%D5%AF%D5%A1%D5%B6%20%D5%A4%D5%A1%D5%BD%D5%AB%20%2A%2A%0A-%20%2A%2A%D4%BE%D5%A1%D5%B6%D5%B8%D5%A9%D5%A1%D6%81%D5%A5%D6%84%20%D5%A3%D5%B6%D5%A5%D6%80%D5%AB%D5%B6%2A%2A%0A-%20%2A%2A%D5%8F%D5%A5%D5%BD%D5%A5%D6%84%20%D5%B4%D5%A5%D6%80%20Instagram%20%D5%A7%D5%BB%D5%A8%2A%2A%0A%F0%9F%91%89%20%D4%B2%D5%B8%D5%AC%D5%B8%D6%80%20%D5%B4%D5%A1%D5%B6%D6%80%D5%A1%D5%B4%D5%A1%D5%BD%D5%B6%D5%A5%D6%80%D5%A8%20%D5%BA%D5%A1%D5%B7%D5%BF%D5%B8%D5%B6%D5%A1%D5%AF%D5%A1%D5%B6%20%D5%A7%D5%BB%D5%B8%D6%82%D5%B4%3A%20${safelyReferrerUrl}`;
  const telegramLinkEn = `https://t.me/share?url=**I%20highly%20recommend%20the%20excellent%20online%20chess%20school%20%22Ararat%22!**&text=%0A%F0%9F%8C%9F%20%2A%2AAdvantages%20of%20the%20international%20school%20%22Ararat%22%3A%2A%2A%0Ahttps%3A%2F%2Fdrive.google.com%2Ffile%2Fd%2F1sq2Fyi-wWbpamea-O2gizcVsbkbiMGG-%2Fview%20%20%20%0A%0A-%20%2A%2ASign%20up%20for%20a%20free%20trial%20lesson%2A%2A%20%0A-%20%2A%2ACheck%20the%20prices%2A%2A%0A-%20%2A%2AVisit%20our%20Instagram%20page%2A%2A%0A%F0%9F%91%89%20All%20details%20are%20available%20on%20the%20official%20page%3A%20${safelyReferrerUrl}%0A%0A%2A%2AWant%20to%20earn%20money%20while%20supporting%20the%20school%3F%2A%2A%0AYes%2C%20it%20is%20possible%2C%20even%20if%20you%20are%20not%20yet%20studying%20at%20our%20school%21%0A%0A%F0%9F%93%8C%20%2A%2AWhat%20to%20do%3A%2A%2A%0A1%EF%B8%8F%E2%83%A3%20%F0%9F%93%A9%20Send%20this%20message%3A%20%28Facebook%2C%20Instagram%2C%20WhatsApp%2C%20Telegram%2C%20Viber%29%3A%0A-%20To%20parent%20groups%20in%20schools%20and%20kindergartens%2C%0A-%20To%20your%20friends%20and%20acquaintances%20who%20have%20children%20aged%204%20and%20above.%0A%0A2%EF%B8%8F%E2%83%A3%20Post%20or%20share%20a%20story%20in%20your%20social%20media%20%28Facebook%2C%20Instagram%2C%20VK%29.%0A%0A%F0%9F%93%9E%20%2A%2AContact%20%20%28WhatsApp%20-%20https%3A%2F%2Fwa.me%2F%2B37443449221%20%29%20the%20school%20administrator%20to%20receive%3A%2A%2A%0A-%20A%20ready-to-send%20recommendation%20text%2C%0A-%20Beautiful%20templates%20for%20your%20posts%2C%0A-%20A%20unique%20link%20to%20track%20results%20and%20calculate%20bonuses.%0A%0A%F0%9F%8E%89%20Thank%20you%20for%20supporting%20our%20school%20and%20helping%20develop%20chess%21%20%F0%9F%99%8C`;
  const telegramLink = popupLanguage === Elanguages.RU ? telegramLinkRu : popupLanguage === Elanguages.AM ? telegramLinkAm : telegramLinkEn

  const whatsappLink = isDeviceMobile()
    ? `whatsapp://send?text=${encodeURIComponent(shareFullText.replace(/\*\*/g, '*'))}`
    : `https://web.whatsapp.com/send?text=${encodeURIComponent(shareFullText.replace(/\*\*/g, '*'))}`;


  return (
    <Modal
      active={active}
      setActive={onClose}
      closeBtnStyle={'!text-black'}
      className={`${classNames.container} !p-[0px] !bg-white rounded-[20px]`}
    >
      <div>
        <div className={`${classNames.container}`}>
          <h2 className={classNames.title}>
            {recommendationTitleText[popupLanguage]}
          </h2>
          <div className="relative flex gap-5">
            <div className="flex items-center justify-between flex-col sm:flex-row w-full gap-2 max-md:gap-5 mb-6">
              <div className="flex gap-[6px]">
                {Object.values(languages).map((item) => (
                  <button
                    key={item.text}
                    onClick={() => onLangChange(item.text)}
                    className={`flex gap-[2px] p-[4px] items-center ${
                      popupLanguage === item.text
                        ? 'bg-[#ccc] rounded-full'
                        : ''
                    }`}
                  >
                    <img
                      src={item.img}
                      alt="language"
                      key={item.text}
                      className="rounded-full w-[20px]"
                    />
                    <span>{item.shortName}</span>
                  </button>
                ))}
              </div>
              <div className="flex items-center gap-2 md:gap-5 max-md:flex-col max-md:justify-center">
              <button
                onClick={toggleShareBtnsShowing}
                className="bg-[#e1e3e8] py-[10px] px-[20px] text-[#424242] text-[16px] font-medium block  border-0 max-w-[200px] w-full "
              >
                {shareText[popupLanguage]}
              </button>
                <div
                  className={`bottom-[-5px] right-0 rounded-[5px] px-[10px] py-[6px] bg-[#ddd] flex items-center gap-[8px] ${
                    !isshareBtnsShowing ? '' : ''
                  }`}
                >
                  <button
                    onClick={copyText}
                    className="border-0 bg-transparent cursor-pointer relative"
                  >
                    <img src={copyImg} alt="copy" className="w-[25px] min-w-[25px] h-[25px]" />
                    <span
                      className={`absolute top-[-20px] left-[50%] translate-x-[-50%] text-[14px] text-black translate-y-[-100%] whitespace-nowrap rounded-[5px] p-[6px] bg-[#ddd] transition text-center ${
                        isCopied ? 'opacity-100' : 'opacity-0'
                      }`}
                    >
                  {copiedText[popupLanguage]}
                </span>
                  </button>
                  <button className="border-0 bg-transparent cursor-pointer">
                    <a
                      target="_blank"
                      href={whatsappLink}
                      className="border-0 bg-transparent cursor-pointer"
                    >
                      <img src={whatsappImg} alt="copy" className="w-[35px] min-w-[35px] h-[35px]" />
                    </a>
                  </button>
                  {/*<TelegramShareButton url={referrerUrl} title={telegramShareText}>*/}
                  {/*  <img src={telegramImg} alt="copy" className="w-[35px] min-w-[35px] h-[35px]" />*/}
                  {/*</TelegramShareButton>*/}
                  <button className="border-0 bg-transparent cursor-pointer">
                    <a target="_blank" href={telegramLink}>
                      <img src={telegramImg} alt="copy" className="w-[35px] min-w-[35px] h-[35px]" />
                    </a>
                  </button>
                  <button className="border-0 bg-transparent cursor-pointer md:hidden">
                    <a
                      href={`viber://forward?text=${viberText}`}
                      target="_blank"
                    >
                      <img src={viberImg} alt="copy" className="w-[35px] min-w-[35px] h-[35px]" />
                    </a>
                  </button>
                  {/* <button className="border-0 bg-transparent cursor-pointer md:hidden">
                <a
                  target="_blank"
                  href={`fb-messenger://share?text=${encodeURIComponent(shareFullText)}`}
                >
                  <img src={messengerImg} alt="copy" className="w-[35px] min-w-[35px] h-[35px]" />
                </a>
              </button> */}
                  <button className="border-0 bg-transparent cursor-pointer">
                    <a
                      target="_blank"
                      href={`http://www.facebook.com`}
                    >
                      <img src={facebookImg} alt="copy" className="w-[35px] min-w-[35px] h-[35px]" />
                    </a>
                  </button>
                  <button className="border-0 bg-transparent cursor-pointer">
                    <a
                      target="_blank"
                      href={`http://instagram.com`}
                    >
                      <img src={instagramImg} alt="copy" className="w-[35px] min-w-[35px] h-[35px]" />
                    </a>
                  </button>
                  <button className="border-0 bg-transparent cursor-pointer">
                    <a
                      target="_blank"
                      href={`https://twitter.com/intent/tweet`}
                    >
                      <img src={twitterImg} alt="copy" className="w-[35px] min-w-[35px] h-[35px]" />
                    </a>
                  </button>
                </div>
              </div>
            </div>


          </div>
          <div className="text-[14px] block text-black whitespace-pre-wrap leading-[1.6] py-[10px]">
            {recommendationText[popupLanguage]}
            <br />
            <br />
            {advantagesText[popupLanguage]} <br />
            <a
              href={advantageLinkText[popupLanguage]}
              target="_blank"
              rel="noopener"
            >
              <p className="text-[#216ba5] mb-3">
                {advantageLinkText[popupLanguage]}
              </p>
            </a>
            {popupLanguage === Elanguages.RU && (
              <>
                <p className="mb-6">
                  - <b>Запишитесь на бесплатный пробный урок</b> <br />-{' '}
                  <b>Узнайте цены</b> <br />- <b>Посмотрите наш Instagram</b>{' '}
                  <br />
                  👉 Все подробности на официальной странице:{' '}
                  <a className="text-[#216ba5] break-all" href={referrerUrl}>
                    {referrerUrl}
                  </a>
                </p>
                <p className="mb-3">
                  <b>Хотите заработать, помогая школе?</b> <br />
                  Да, это возможно, даже если вы ещё не учитесь в нашей школе!
                </p>
                <p className="mb-3">
                  <b>📌 Что нужно сделать:</b> <br />
                  1️⃣ Отправьте это сообщение: (Facebook, Instagramm, WhatsApp,
                  Telegram, Viber). <br />
                  - В родительских группах школ и детсадов, <br />
                  - Близким и друзьям, у которых есть дети от 4 лет. <br />
                  2️⃣ Разместите пост или сторис в своих соцсетях (Facebook,
                  Instagram, VK). <br />
                </p>
                <p className="mb-3">
                  📞{' '}
                  <b>
                    Напишите ( WhatsApp - <a href="https://wa.me/+37443449221">https://wa.me/+37443449221</a> )
                    администратору школы, чтобы получить:
                  </b>{' '}
                  <br />
                  - Готовый текст рекомендации, <br />
                  - Красивые макеты для публикаций, <br />
                  - Уникальную ссылку для отслеживания результата и получения
                  бонусов. <br />
                </p>
                <p className="mb-3">
                  Спасибо за поддержку нашей школы и за помощь в развитии
                  шахмат! 🙌
                </p>
              </>
            )}
            {popupLanguage === Elanguages.AM && (
              <>
                <p className="mb-6">
                  - <b>Գրանցվեք անվճար փորձնական դասի </b> <br />-{' '}
                  <b>Ծանոթացեք գներին</b> <br />- <b>Տեսեք մեր Instagram էջը</b>{' '}
                  <br />
                  👉 Բոլոր մանրամասները պաշտոնական էջում:{' '}
                  <a className="text-[#216ba5] break-all" href={referrerUrl}>
                    {referrerUrl}
                  </a>
                </p>
                <p className="mb-3">
                  <b>Ուզո՞ւմ եք գումար վաստակել՝ աջակցելով դպրոցին:</b> <br />
                  Այո, դա հնարավոր է, նույնիսկ եթե դուք դեռ չեք սովորում մեր
                  դպրոցում!
                </p>
                <p className="mb-3">
                  <b>📌 Ինչ պետք է անել:</b> <br />
                  1️⃣ Ուղարկեք այս հաղորդագրությունը: (Facebook, Instagram,
                  WhatsApp, Telegram, Viber): <br />
                  - Դպրոցների և մանկապարտեզների ծնողական խմբերում, <br />
                  - Ձեր ընկերներին և ծանոթներին, ովքեր ունեն 4 տարեկանից մեծ
                  երեխաներ։ <br />
                  2️⃣ Տեղադրեք պոստ կամ սթորի ձեր սոցիալական ցանցերում (Facebook,
                  Instagram, VK): <br />
                </p>
                <p className="mb-3">
                  📞{' '}
                  <b>
                    Կապ հաստատեք ( WhatsApp - <a href="https://wa.me/+37443449221">https://wa.me/+37443449221</a> )
                    դպրոցի ադմինիստրատորի հետ՝ ստանալու համար:
                  </b>{' '}
                  <br />
                  - Րեկոմենդացիայի պատրաստի տեքստը, <br />
                  - Գեղեցիկ ձևավորումներ պոստերի համար, <br />
                  - Ռեֆերալ հղում՝ արդյունքների հետևման և բոնուսների հաշվարկի
                  համար։ <br />
                </p>
                <p className="mb-3">
                  Շնորհակալություն մեր դպրոցին աջակցելու և շախմատը զարգացնելու
                  համար։ 🙌
                </p>
              </>
            )}
            {popupLanguage === Elanguages.EN && (
              <>
                <p className="mb-6">
                  - <b>Sign up for a free trial lesson</b> <br />-{' '}
                  <b>Check the prices</b> <br />- <b>Visit our Instagram page</b>{' '}
                  <br />
                  👉 All details are available on the official page:{' '}
                  <a className="text-[#216ba5] break-all" href={referrerUrl}>
                    {referrerUrl}
                  </a>
                </p>
                <p className="mb-3">
                  <b>Want to earn money while supporting the school?</b> <br />
                  Yes, it is possible, even if you are not yet studying at our school!
                </p>
                <p className="mb-3">
                  <b>📌 What to do:</b> <br />
                  1️⃣ Send this message: (Facebook, Instagram, WhatsApp, Telegram, Viber): <br />
                  - To parent groups in schools and kindergartens, <br />
                  - To your friends and acquaintances who have children aged 4 and above. <br />
                  2️⃣ Post or share a story in your social media (Facebook, Instagram, VK).  <br />
                </p>
                <p className="mb-3">
                  📞{' '}
                  <b>
                    Contact  (WhatsApp - <a href="https://wa.me/+37443449221">https://wa.me/+37443449221</a> ) the school administrator to receive:
                  </b>{' '}
                  <br />
                  - A ready-to-send recommendation text, <br />
                  - Beautiful templates for your posts, <br />
                  - A unique link to track results and calculate bonuses. <br />
                </p>
                <p className="mb-3">
                  Thank you for supporting our school and helping develop chess! 🙌
                </p>
              </>
            )}
            {/* <p className="mb-3">
              {advantage1Text[popupLanguage][0]}
              <strong>{advantage1Text[popupLanguage][1]}</strong>{' '}
              {advantage1Text[popupLanguage][2]}
            </p>
            <div className="flex">
              <p className="text-[#ff0000] font-bold">
                {advantage4Text[popupLanguage]}{' '}
              </p>
              <a
                href={advantage4LinkText[popupLanguage]}
                target="_blank"
                rel="noopener"
              >
                <p className="text-[#216ba5] mb-5">
                  {advantage4LinkText[popupLanguage]}
                </p>
              </a>
            </div>
            <p className="font-bold">
              {registerForFreeLessonText[popupLanguage]}
            </p>
            <p className="font-bold">{priceSiteText[popupLanguage]}</p>
            <p className="font-bold mb-2">{instagramText[popupLanguage]}</p>
            <p>{officialSiteText[popupLanguage]}</p>
            <a className="text-[#216ba5] break-all" href={referrerUrl}>
              {referrerUrl}
            </a> */}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default HomeRecomendModal;
